import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";

import { Grid } from "@mui/material";

import {
  dayjs,
  Icons,
  Skeleton,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";

import { getFromLS } from "@flash-tecnologia/hros-web-utility";

import { FormCard } from "@components/Cards";
import { PageTemplate } from "@components/PageTemplate";
import { DatePeriodBox } from "./components/DatePeriodBox";

import { ConfirmationModal } from "@components/Modals";

import { routes } from "@routes";
import { trpc } from "@api/client";

import { dispatchToast, RequiredAsterisk, StyledText, track } from "@utils";

import { Title, TitleArea } from "./styled";

import { CreateEditCycleFormProps, validationSchema } from "./types";

import type { Step } from "server/src/services/cycle/types";

import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

dayjs.tz.setDefault("America/Sao_Paulo");

export const PageCreateCycle = () => {
  const { _id = "" } = useParams();

  const navigate = useNavigate();

  const utils = trpc.useContext();

  const userAuth = getFromLS("userAuth");

  const name = userAuth?.attributes?.name || "";
  const splittedName = name.split(" ")?.[0] || "";

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const isEdit = useMemo(() => !!_id, [_id]);

  const {
    data: cycle,
    isFetching: isLoading,
    error,
  } = trpc.performance.cycle.getCycleById.useQuery(
    { cycleId: _id },
    {
      enabled: isEdit,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        const cycleNotExists = e?.data?.error === "CYCLE_NOT_EXISTS_ERROR";

        const message = cycleNotExists
          ? "Erro ao buscar ciclo, ciclo não foi encontrado!"
          : "Erro ao buscar ciclo, tente novamente mais tarde!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    }
  );

  const refetch = () => {
    utils.performance.cycle.getAllCycles.refetch();
    utils.performance.cycle.getLastCycle.refetch();
    utils.performance.cycle.getCycleById.invalidate();
    utils.performance.evaluation.getEvaluationDetailsMatrix.invalidate();
    utils.performance.evaluation.getAllEvaluationsPaginated.invalidate();
    utils.performance.evaluation.getEvaluationsByEvaluationCycle.invalidate();
  };

  const handleDone = () => {
    navigate(routes.PageManageCycles);
    refetch();
  };

  const { mutate: updateMutate, isLoading: updateLoading } =
    trpc.performance.cycle.updateCycle.useMutation({
      onSuccess: () => {
        navigate(routes.PageManageCycles);

        dispatchToast({
          type: "success",
          content: "Ciclo editado com sucesso!",
        });

        handleDone();
      },
      onError: (e: any) => {
        const cycleExists = e?.data?.error === "CYCLE_EXISTS_ERROR";

        const message = cycleExists
          ? "Erro ao editar ciclo, ciclo com mesmo nome já existe!"
          : "Erro ao editar o ciclo, tente novamente em breve.";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const { mutate: createMutate, isLoading: createLoading } =
    trpc.performance.cycle.createCycle.useMutation({
      onSuccess: ({ hasCycle }) => {
        if (!hasCycle) {
          setCreateModalOpen(true);
          return;
        }

        dispatchToast({
          type: "success",
          content: "Ciclo criado com sucesso!",
        });

        handleDone();
      },
      onError: (e: any) => {
        const cycleExists = e?.data?.error === "CYCLE_EXISTS_ERROR";

        const message = cycleExists
          ? "Erro ao criar ciclo, ciclo com mesmo nome já existe!"
          : "Erro ao criar o ciclo, tente novamente em breve.";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  useEffect(() => {
    if (!cycle) return;

    const evaluation = (cycle?.steps || []).find(
      ({ type }) => type === "evaluation"
    );

    const calibration = (cycle?.steps || []).find(
      ({ type }) => type === "calibration"
    );

    const feedback = (cycle?.steps || []).find(
      ({ type }) => type === "feedback"
    );

    const idp = (cycle?.steps || []).find(({ type }) => type === "idp");

    formik.setValues({
      ...formik.initialValues,
      name: cycle?.name,
      description: cycle?.description || "",
      evaluationCheckbox: !!evaluation,
      evaluationStartDate: evaluation
        ? dayjs(evaluation?.startDate).toString()
        : "",
      evaluationEndDate: evaluation
        ? dayjs(evaluation?.endDate).toString()
        : "",
      calibrationCheckbox: !!calibration,
      calibrationStartDate: calibration
        ? dayjs(calibration?.startDate).toString()
        : "",
      calibrationEndDate: calibration
        ? dayjs(calibration?.endDate).toString()
        : "",
      feedbackCheckbox: !!feedback,
      feedbackStartDate: feedback ? dayjs(feedback?.startDate).toString() : "",
      feedbackEndDate: feedback ? dayjs(feedback?.endDate).toString() : "",
      idpCheckbox: !!idp,
      idpStartDate: idp ? dayjs(idp?.startDate).toString() : "",
      idpEndDate: idp ? dayjs(idp?.endDate).toString() : "",
    });
  }, [cycle]);

  const breadcrumbs = useMemo(
    () => [
      {
        label: "Ciclos de desempenho",
        route: routes.PageManageCycles,
      },
      {
        label: `${isEdit ? "Editar" : "Criar"} ciclo`,
        route: "",
      },
    ],
    [isEdit]
  );

  const formik = useFormik<CreateEditCycleFormProps>({
    initialValues: {
      name: "",
      description: "",
      evaluationCheckbox: true,
      evaluationStartDate: "",
      evaluationEndDate: "",
      calibrationCheckbox: false,
      calibrationStartDate: "",
      calibrationEndDate: "",
      feedbackCheckbox: false,
      feedbackStartDate: "",
      feedbackEndDate: "",
      idpCheckbox: false,
      idpStartDate: "",
      idpEndDate: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      track({
        name: "people_strategic_hr_performance_company_cycles_createcycle_save_clicked",
      });

      const steps: Step[] = [];

      if (values?.evaluationStartDate && values?.evaluationCheckbox) {
        steps.push({
          type: "evaluation",
          startDate: values?.evaluationStartDate,
          endDate: values?.evaluationEndDate,
        });
      }

      if (values?.calibrationStartDate && values?.calibrationCheckbox) {
        steps.push({
          type: "calibration",
          startDate: values?.calibrationStartDate,
          endDate: values?.calibrationEndDate,
        });
      }

      if (values?.feedbackStartDate && values?.feedbackCheckbox) {
        steps.push({
          type: "feedback",
          startDate: values?.feedbackStartDate,
          endDate: values?.feedbackEndDate,
        });
      }

      if (values?.idpStartDate && values?.idpCheckbox) {
        steps.push({
          type: "idp",
          startDate: values?.idpStartDate,
          endDate: values?.idpEndDate,
        });
      }

      const params = {
        name: values?.name,
        description: values?.description,
        steps: steps,
      };

      if (isEdit) updateMutate({ cycleId: _id, params });
      else createMutate({ params });
    },
  });

  const isEvaluationDone = useMemo(() => {
    const formikStartDate = formik.values.evaluationStartDate;
    const formikEndDate = formik.values.evaluationEndDate;

    if (!formikStartDate || !formikEndDate) return false;

    const now = dayjs.tz(dayjs());
    const endDate = dayjs.tz(formikEndDate);

    return !!endDate?.isBefore(now);
  }, [formik.values.evaluationStartDate, formik.values.evaluationEndDate]);

  const calibrationStarted = useMemo(() => {
    const formikStartDate = formik.values.calibrationStartDate;

    if (!formikStartDate) return false;

    const now = dayjs.tz(dayjs());
    const startDate = dayjs.tz(formikStartDate);

    return !!now?.isAfter(startDate);
  }, [formik.values.calibrationStartDate]);

  return (
    <PageTemplate
      routes={breadcrumbs || []}
      footer={{
        cancelProps: {
          title: "Sair",
          callback: () => navigate(routes.PageManageCycles),
        },
        confirmProps: {
          title: (
            <>
              {isEdit ? "Editar" : "Criar"} ciclo
              <Icons name="IconCheck" fill="transparent" />
            </>
          ),
          disabled: updateLoading || createLoading || isLoading || !!error,
          loading: updateLoading || createLoading,
          callback: () => {
            formik.validateForm().then((errors) => {
              const {
                calibrationStartDate,
                calibrationEndDate,
                feedbackStartDate,
                feedbackEndDate,
                idpStartDate,
                idpEndDate,
              } = errors;

              if (
                calibrationStartDate ||
                calibrationEndDate ||
                feedbackStartDate ||
                feedbackEndDate ||
                idpStartDate ||
                idpEndDate
              ) {
                dispatchToast({
                  type: "error",
                  content:
                    "Uma etapa só pode iniciar após o encerramento da etapa anterior, nunca no mesmo dia ou antes. Apenas feedbacks e PDI podem ocorrer dentro do mesmo período.",
                });
                return;
              }
            });

            formik.handleSubmit();
          },
        },
      }}
    >
      <Title variant="headline6" setColor="neutral20">
        {isEdit ? "Editar" : "Criar"} ciclo
      </Title>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4} lg={3} style={{ width: "100%" }}>
          <TitleArea>
            <StyledText variant="headline7" setColor="secondary50">
              Como será o seu ciclo?
            </StyledText>
            <StyledText variant="body3" setColor="neutral50">
              Defina as características gerais do ciclo de performance.
            </StyledText>
          </TitleArea>
        </Grid>
        <Grid item sm={12} md={8} lg={9} style={{ width: "100%" }}>
          <FormCard
            title="Informações básicas"
            description="Insira o nome e descrição do ciclo de desempenho."
            mandatory={true}
            style={{ marginBottom: "40px" }}
          >
            <div>
              {isLoading ? (
                <Skeleton width={"100%"} height={"58px"} />
              ) : (
                <TextField
                  label={
                    <StyledText variant="body3" setColor="neutral30">
                      Nome do ciclo
                      <RequiredAsterisk />
                    </StyledText>
                  }
                  value={formik.values.name}
                  onChange={(e) =>
                    formik.handleChange({
                      target: { id: "name", value: e.target.value },
                    })
                  }
                  fullWidth
                  disabled={!!error}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            </div>
            <div>
              {isLoading ? (
                <Skeleton width={"100%"} height={"104px"} />
              ) : (
                <TextField
                  label="Descrição"
                  id={"description"}
                  name={"description"}
                  multiline={true}
                  rows={3}
                  inputProps={{ maxLength: 1000 }}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  fullWidth
                  disabled={!!error}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              )}
            </div>
          </FormCard>
          <FormCard
            title="Etapas do ciclo"
            description="Defina as etapas do ciclo. Os prazos definidos impactarão todas as avaliações do ciclo, porém você poderá editá-las sempre que necessário."
            mandatory={true}
            style={{ marginBottom: "40px" }}
          >
            <DatePeriodBox
              title="Avaliações de desempenho"
              description="Analise o desempenho individual dos colaboradores em relação às metas e expectativas organizacionais."
              tooltipInfo={
                <StyledText
                  variant="body4"
                  fontWeight="600"
                  setColor="neutral100"
                >
                  Todos os ciclos devem conter <b>pelo menos uma avaliação.</b>
                  <br />
                  <br />
                  <b>Importante:</b> Seu ciclo pode conter{" "}
                  <b>uma ou mais avaliações.</b> Caso avalie todos da mesma
                  forma, uma única avaliação é suficiente. Para avaliações
                  personalizadas, crie <b>uma para cada área ou cargo</b> dentro
                  do mesmo ciclo.
                </StyledText>
              }
              startLabel="Início das avaliações"
              startProp="evaluationStartDate"
              endLabel="Encerramento das avaliações"
              endProp="evaluationEndDate"
              checkboxProp="evaluationCheckbox"
              tracker="people_strategic_hr_performance_company_cycles_createcycle_evaluation_selected"
              formik={formik}
              isLoading={isLoading}
              disabledAddStep={isEvaluationDone}
              disabledStartDateChanges={isEvaluationDone && calibrationStarted}
              disabledEndDateChanges={isEvaluationDone && calibrationStarted}
            />
            <DatePeriodBox
              title="Calibração de notas"
              description="Ajuste as notas e garanta que as avaliações sejam justas e consistentes."
              tooltipInfo={
                <StyledText
                  variant="body4"
                  fontWeight="600"
                  setColor="neutral100"
                >
                  Ao selecionar a calibração, se torna obrigatória a realização
                  das avaliações de líder (líder avalia liderado).
                </StyledText>
              }
              startLabel="Início da calibração"
              startProp="calibrationStartDate"
              endLabel="Encerramento da calibração"
              endProp="calibrationEndDate"
              checkboxProp="calibrationCheckbox"
              prevDateProp="evaluationEndDate"
              tracker="people_strategic_hr_performance_company_cycles_createcycle_calibration_selected"
              formik={formik}
              isLoading={isLoading}
              disabledAddStep={isEvaluationDone}
              disabledStartDateChanges={isEvaluationDone && calibrationStarted}
            />
            <DatePeriodBox
              title="Feedbacks"
              description="Envie feedbacks sobre pontos fortes e áreas de melhoria para promover o crescimento profissional dos funcionários."
              startLabel="Início dos feedbacks"
              startProp="feedbackStartDate"
              endLabel="Encerramento dos feedbacks"
              endProp="feedbackEndDate"
              checkboxProp="feedbackCheckbox"
              prevDateProp={
                formik.values?.calibrationCheckbox
                  ? "calibrationEndDate"
                  : "evaluationEndDate"
              }
              tracker="people_strategic_hr_performance_company_cycles_createcycle_feedbacks_selected"
              formik={formik}
              isLoading={isLoading}
              disabledAddStep={isEvaluationDone}
            />
            <DatePeriodBox
              title="PDI"
              description="Crie planos personalizados para ajudar os funcionários a alcançarem seu potencial máximo e atingirem seus objetivos de carreira."
              startLabel="Início do PDI"
              startProp="idpStartDate"
              endLabel="Encerramento do PDI"
              endProp="idpEndDate"
              checkboxProp="idpCheckbox"
              prevDateProp={
                formik.values?.calibrationCheckbox
                  ? "calibrationEndDate"
                  : "evaluationEndDate"
              }
              tracker="people_strategic_hr_performance_company_cycles_createcycle_idp_selected"
              formik={formik}
              isLoading={isLoading}
              disabledAddStep={isEvaluationDone}
            />
          </FormCard>
        </Grid>
      </Grid>
      <ConfirmationModal
        open={createModalOpen}
        showWarning
        warningTitle={splittedName ? `Parabéns, ${splittedName}!` : "Parabéns"}
        variantType="default"
        primaryColor="primary"
        icon="IconCheck"
        title="Você acaba de criar seu primeiro ciclo de desempenho!"
        subTitle="Siga o passo-a-passo para finalizar a configuração do seu ciclo de performance."
        confirm={{
          title: "Prosseguir",
        }}
        cancel={{ hide: true }}
        onClose={() => setCreateModalOpen(false)}
        onConfirm={handleDone}
      />
    </PageTemplate>
  );
};
