import { NewsModal } from "@components/Modals";
import { useEffect, useState } from "react";

export const InfoPortal = ({ children }) => {
  const [newsModal, setNewsModal] = useState(false);

  useEffect(() => {
    const viewedModal = localStorage?.getItem("viewedNewsModal") ?? "";
    if (viewedModal === "true") {
      setNewsModal(false);
    } else setNewsModal(true);
  }, [localStorage]);

  return (
    <>
      <NewsModal
        open={newsModal}
        onClose={() => {
          localStorage?.setItem("viewedNewsModal", "true");
          setNewsModal(false);
        }}
        onConfirm={() => setNewsModal(false)}
      />
      {children}
    </>
  );
};
