import { avatarMaker } from "@utils";

import {
  AvatarContainer,
  Description,
  ProfileArea,
  StyledText,
} from "./styled";

interface ProfileTableProps {
  name: string;
  email?: string | React.ReactNode;
}

export const ProfileTable = ({ name, email }: ProfileTableProps) => {
  const renderDescription = (description: string | React.ReactNode) => {
    const isString = typeof description === "string";

    if (isString) return <Description variant="body4">{email}</Description>;
    return description;
  };

  return (
    <ProfileArea>
      <AvatarContainer children={avatarMaker(name) || "-"} />
      <div>
        <StyledText variant="body3">{name || "-"}</StyledText>
        {email && renderDescription(email)}
      </div>
    </ProfileArea>
  );
};
