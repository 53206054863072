import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import type { CommonModalProps } from "@components/Modals/types";

import {
  CloseButton,
  FooterContainer,
  HeaderContainer,
  ImgInfo,
  StyledList,
  StyledModal,
  TextArea,
  TitleNews,
} from "./styled";
import { StyledText } from "@utils";
import { useTheme } from "styled-components";
import { MatrixNews } from "@assets/index";

export const NewsModal = ({ open, onClose }: CommonModalProps) => {
  const theme = useTheme();

  const Footer = () => (
    <FooterContainer>
      <LinkButton
        variant="neutral"
        children="Ver mais tarde"
        style={{ alignSelf: "center" }}
        onClick={onClose}
      />
      <Button
        size="large"
        variant="primary"
        disabled={false}
        style={{ alignSelf: "center" }}
        onClick={onClose}
      >
        Começar!
      </Button>
    </FooterContainer>
  );

  return (
    <StyledModal onClose={onClose} open={open} footer={<Footer />}>
      <>
        <div>
          <ImgInfo src={MatrixNews} />
          <HeaderContainer>
            <CloseButton
              variant={"line"}
              size={"small"}
              icon="IconX"
              onClick={onClose}
            />
          </HeaderContainer>
        </div>
        <TextArea>
          <TitleNews
            variant="body4"
            variantColor={theme.colors.secondary[50]}
            fontWeight={700}
            style={{ marginBottom: "12px" }}
          >
            <Icons
              name="IconSparkles"
              fill="transparent"
              color={theme.colors.secondary[50]}
            />
            Novidade!
          </TitleNews>
          <StyledText
            variant="headline8"
            variantColor={theme.colors.neutral[10]}
            fontWeight={700}
            style={{ marginBottom: "8px" }}
          >
            Conheça nossa matriz de calibração!
          </StyledText>
          <StyledList>
            <li>
              Crie e personalize a matriz no processo de avaliação de
              desempenho.
            </li>
            <li>
              Calibre notas e compare o posicionamento dos colaboradores
              diretamente na matriz.
            </li>
          </StyledList>
        </TextArea>
      </>
    </StyledModal>
  );
};
